<template>
  <div :class="{xs12: isExportable, xl3: isExportable, md6: isExportable}" class="flex">

    <vac-card
      v-if="isExportable"
      :title="label"
      class="chart-widget">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        :header="label"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />
      <Actions
        v-if="isExportable"
        show-percentage-switcher
        :active-switcher-button="activeSwitcherButton"
        :export-to-excel="exportToExcel"
        is-preview
        @togglePreview="togglePreview"
        @toggle-percentage="onToggle($event)"
      />


      <Doughnut
        v-if="!label.includes('categories')"
        :dataset-id-key="'label'"
        :height="500"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :css-classes="'doughnut export'"
        :style="{height: '500px'}"
      />

      <template v-else>
        <Doughnut
          :dataset-id-key="'label'"
          :height="500"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :css-classes="'doughnut export'"
          :style="{height: '500px'}"
        />
      </template>
    </vac-card>

    <vac-card
      v-else
      :style="{margin: '10px 0'}"
      :title="label"
      class="chart-widget">

      <Doughnut
        v-if="!label.includes('categories')"
        :dataset-id-key="'label'"
        :height="400"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :css-classes="'doughnut-small export'"
        :style="{height: '400px'}"
      />

      <template v-else>
        <Doughnut
          :dataset-id-key="'label'"
          id="export-doughnut"
          :height="400"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :css-classes="'doughnut-small export'"
          :style="{height: '400px'}"
        />
      </template>
    </vac-card>

  </div>
</template>

<script>
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";
import HelperExcel from '../../services/Helpers/HelperExcel';
import Actions from "../actions/Actions";
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

// const alwaysShowTooltip = {
//   id: 'alwaysShowTooltip',
//   beforeRender(chart) {
//     if (chart.config.options.showAllTooltips) {
//       // create an array of tooltips
//       // we can't use the chart tooltip because there is only one tooltip per chart
//       chart.pluginTooltips = [];
//       chart.config.data.datasets.forEach(function(dataset, i) {
//         chart.getDatasetMeta(i).data.forEach(function(sector, j) {
//           chart.pluginTooltips.push(new Chart.Tooltip({
//             _chart: chart.chart,
//             _chartInstance: chart,
//             _data: chart.data,
//             _options: chart.options.tooltips,
//             _active: [sector]
//           }, chart));
//         });
//       });
//       // turn off normal tooltips
//       chart.options.tooltips.enabled = false;
//     }
//   },
//   afterDraw(chart, easing) {
//     if (chart.config.options.showAllTooltips) {
//       // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
//       if (!chart.allTooltipsOnce) {
//         if (easing !== 1)
//           return;
//         chart.allTooltipsOnce = true;
//       }
//       // turn on tooltips
//       chart.options.tooltips.enabled = true;
//       Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
//         tooltip.initialize();
//         tooltip.update();
//         // we don't actually need this since we are not animating tooltips
//         tooltip.pivot();
//         tooltip.transition(easing).draw();
//       });
//       chart.options.tooltips.enabled = false;
//     }
//   }
//
// }

export default {
  name: 'PieTime',
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    showValuesInLabels: {
      type: Boolean,
      default: false,
    },
    isExportable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "Pie Chart"
    }
  },
  components: {
    // VacChart,
    // VaChart,
    Actions,
    ExcelPreviewModal,
    Doughnut
  },
  data() {
    return {
      isPreview: false,
      activeSwitcherButton: 1,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true
          },
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          }
        }


      }
    }
  },
  computed: {
    chartData() {
      if (this.showValuesInLabels) {
        let formattedLabels = this.data.labels.map((l, index) => {
          return `${l}: ${this.data.datasets[0].data[index]}`
        })
        return {
          datasets: this.data.datasets,
          labels: formattedLabels,
        }
      } else {
        return this.data
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        showAllTooltips: true,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (ttItem) => {
                if (this.activeSwitcherButton === 1) {
                  return `${ttItem.label}: ${ttItem.parsed}`;
                } else {
                  let sum = 0;
                  let dataArr = ttItem.dataset.data;
                  dataArr.map(data => {
                    sum += Number(data);
                  });

                  let percentage = (ttItem.parsed * 100 / sum).toFixed(2) + '%';
                  return `${ttItem.label}: ${percentage}`;
                }
              }
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle',
            }
          }
        }
      }
    },
    chartOptionsWithoutLabels() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {

          legend: {
            display: false
          }
        }
      }
    },
    preparedData() {
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      if (this.activeSwitcherButton === 2) {
        let sum = 0
        datasets[0].data.forEach(d => {
          sum += d
        })
        datasets[0].data = datasets[0].data.map(d => {
          return `${(d * 100/sum).toFixed(2)}%`
        })
      }

      const returnObj = {}
      if (this.label.includes("categories"))
        returnObj.labels = ["Categories", "Count"]
      else
        returnObj.labels = ["Retailers", "Count"]
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }

      returnObj.datasets = [];
      datasets.forEach(data => {
        data.data.forEach((info, index) => {
          const obj = {
            data: [labels[index], info]
          };
          returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(labels[index]).length)
          returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(info).length)
          returnObj.datasets.push(obj);
        });
      });
      return returnObj;
    },

  },
  methods: {
    exportToExcel() {
      const customData = [];
      const customLabels = ["Retailer", "Count"];
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      if (this.activeSwitcherButton === 2) {
        let sum = 0
        datasets[0].data.forEach(d => {
          sum += d
        })
        datasets[0].data = datasets[0].data.map(d => {
          return `${(d * 100/sum).toFixed(2)}%`
        })
      }

      datasets.forEach(data => {
        data.data.forEach((info, index) => {
          const obj = {
            "Retailer": labels[index],
            "Count": info,
          };
          customData.push(obj);
        });
      });

      HelperExcel.exportToExcel({}, "Time Stat", customLabels, customData);
    },

    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    onToggle(val) {
      this.activeSwitcherButton = val
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'src/sass/variables';

.labels {
  display: flex;
  align-items: center;
  width: 100%;

  &__wrapper {
    display: flex;
    width: 100%;
    margin: 5px 0;
    justify-content: space-around;
    align-items: center;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      @include clamped-text(1);
    }

    &:nth-child(2) {
      margin-left: 5px;
    }
  }

  &__point {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border-radius: 100%;
    display: block;
    margin-right: 5px;
  }
}

.doughnut {
  height: 500px;
}

.doughnut-small {
  height: 400px;
}
</style>
